{
  "name": "@the-oz/app-sales",
  "version": "4.8.8",
  "description": "",
  "modern": "dist/modern.js",
  "legacy": "dist/legacy.js",
  "standalone": "dist/standalone.js",
  "targets": {
    "modern": {
      "engines": {
        "browsers": "last 2 version, > 0.5%, Firefox ESR, not dead"
      },
      "source": "src/index.js",
      "optimize": true
    },
    "legacy": {
      "engines": {
        "browsers": "last 4 version, > 0.2%, Firefox ESR, not dead"
      },
      "source": "src/index.js",
      "optimize": true
    },
    "standalone": {
      "engines": {
        "browsers": "last 4 version, > 0.2%, Firefox ESR, not dead"
      },
      "source": "src/bundle.js",
      "optimize": true
    }
  },
  "scripts": {
    "format": "npx prettier --write ./src",
    "watch": "parcel watch",
    "dev": "concurrently \"parcel src/index.html --port 12345\" \"parcel watch\"",
    "start": "parcel src/index.html",
    "build": "parcel build",
    "prebuild": "npm run format",
    "prepack": "npm run build"
  },
  "author": "The Oz",
  "license": "ISC",
  "devDependencies": {
    "concurrently": "7.6.0",
    "parcel": "^2.8.0",
    "prettier": "2.8.3"
  },
  "dependencies": {
    "jquery": "^3.6.1"
  }
}
